/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import { Grid, FormLabel, Switch, Stack, Button, Divider, TextField, InputAdornment } from '@mui/material';
import { Formik } from 'formik';
import Spinner from 'components/spinner';
import { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'react-redux';
import settingGQL from 'hooks/settings/useGQL';
import { Settings } from 'types/settings';

const Setting = () => {
    const dispatch = useDispatch();
    const { GET_SETTINGS_QUERY, UPDATE_SETTINGS_MUTATION } = settingGQL();
    const [initialData, setInitialData] = useState<{ isLoading: boolean; data?: Settings[] }>({
        isLoading: true,
        data: []
    });

    const { data, refetch } = GET_SETTINGS_QUERY();
    const [handleUpdate] = UPDATE_SETTINGS_MUTATION();

    useEffect(() => {
        if (data?.getSettings?.settings) {
            setInitialData({ isLoading: false, data: data.getSettings.settings });
        }
    }, [data]);

    const onhandleSubmit = async (values, { setSubmitting }) => {
        try {
            const updatedSettings = await handleUpdate({ variables: { input: values.data } });
            dispatch(
                openSnackbar({
                    open: true,
                    message: updatedSettings?.data?.updateSettings?.message,
                    anchorOrigin: { horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
            refetch();
        } catch (err: any) {
            setSubmitting(false);
            dispatch(
                openSnackbar({
                    open: true,
                    message: err.message,
                    anchorOrigin: { horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        }
    };
    return (
        <MainCard title="Settings" className="primaryTitle" contentSX={{ padding: '0 20px' }} headerSX={{ padding: '20px' }}>
            <Formik initialValues={initialData} enableReinitialize onSubmit={onhandleSubmit}>
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        {values.isLoading ? (
                            <Spinner />
                        ) : (
                            <Grid container spacing={3}>
                                {values?.data?.map((rowData: Settings, key: number) => {
                                    if (rowData?.slug === 'hide-hunting-dogs') {
                                        return (
                                            <>
                                                <Grid item xs={6} sm={4} ml={2}>
                                                    <FormLabel component="legend">Hide hunting dogs</FormLabel>
                                                </Grid>
                                                <Grid item xs={6} sm={6}>
                                                    <Switch
                                                        checked={!!rowData.hide}
                                                        onChange={handleChange}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        name={`data.${key}.hide`}
                                                        color="secondary"
                                                        value={rowData.hide}
                                                    />
                                                </Grid>
                                            </>
                                        );
                                    }

                                    if (rowData?.slug === 'transaction-fees') {
                                        return (
                                            <Grid item xs={6} sm={12} key={key}>
                                                <FormLabel component="legend">Transaction Fees</FormLabel>

                                                {/* Render fee levels interactively */}
                                                {rowData.feeLevels?.map((fee, idx) => (
                                                    <div key={idx}>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Min"
                                                                    type="number"
                                                                    value={values?.data?.[key]?.feeLevels?.[idx]?.min || fee.min}
                                                                    onChange={(e) =>
                                                                        handleChange({
                                                                            target: {
                                                                                name: `data.${key}.feeLevels.${idx}.min`,
                                                                                value: e.target.value
                                                                            }
                                                                        })
                                                                    }
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">Min</InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Max"
                                                                    type="number"
                                                                    value={values?.data?.[key]?.feeLevels?.[idx]?.max || fee.max}
                                                                    onChange={(e) =>
                                                                        handleChange({
                                                                            target: {
                                                                                name: `data.${key}.feeLevels.${idx}.max`,
                                                                                value: e.target.value
                                                                            }
                                                                        })
                                                                    }
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">Max</InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Fee Percentage"
                                                                    type="number"
                                                                    value={
                                                                        values?.data?.[key]?.feeLevels?.[idx]?.feePercentage ||
                                                                        fee.feePercentage
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange({
                                                                            target: {
                                                                                name: `data.${key}.feeLevels.${idx}.feePercentage`,
                                                                                value: e.target.value
                                                                            }
                                                                        })
                                                                    }
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">%</InputAdornment>
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Divider sx={{ my: 2 }} />
                                                    </div>
                                                ))}

                                                {/* Button to add new fee level */}
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => {
                                                        const newFee = {
                                                            min: 0,
                                                            max: 0,
                                                            feePercentage: 0
                                                        };
                                                        const updatedFeeLevels = [...(values?.data?.[key]?.feeLevels || []), newFee];
                                                        setInitialData((prevData) => {
                                                            const updatedData = [...(prevData?.data || [])];
                                                            updatedData[key] = { ...updatedData[key], feeLevels: updatedFeeLevels };
                                                            return { ...prevData, data: updatedData };
                                                        });
                                                    }}
                                                    sx={{ mt: 2 }}
                                                >
                                                    Add Fee Level
                                                </Button>
                                            </Grid>
                                        );
                                    }
                                    return null; // Return null for slugs you don't want to render UI for
                                })}

                                <Grid item xs={12} sx={{ pt: '34px!important', pb: '8px' }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2.75} sx={{ pr: 2 }}>
                                        <Button disabled={isSubmitting} size="large" type="submit" variant="contained" color="secondary">
                                            Save Changes
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                )}
            </Formik>
        </MainCard>
    );
};

export default Setting;
