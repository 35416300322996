import { useMutation, useQuery } from '@apollo/client';
import { GET_SETTINGS } from 'grapqhl/settings/queries';
import { UPDATE_SETTINGS } from 'grapqhl/settings/mutation';
import { SettingsResponse, UpdateSettingsDto } from 'types/settings';

const useGQL = () => {
    const GET_SETTINGS_QUERY = () =>
        useQuery<SettingsResponse>(GET_SETTINGS, { notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only' });
    const UPDATE_SETTINGS_MUTATION = () =>
        useMutation<SettingsResponse, { input: UpdateSettingsDto }>(UPDATE_SETTINGS, {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    return {
        GET_SETTINGS_QUERY,
        UPDATE_SETTINGS_MUTATION
    };
};

export default useGQL;
